<template>
  <div>
    <v-menu
      bottom
      :close-on-content-click="false"
      open-on-hover
      offset-y
      nudge-bottom="10"
      close-delay="100"
      rounded="lg"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="grey lighten-1">
            mdi-cloud-sync
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title>
            <div class="text-body-2">
              {{ value.provider.name }}
              <br>
              {{ value.key }}
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>
